import * as actionTypes from "../../store/actions/actionTypes";
import basicApi from '../../api'
import userApi from "./userApi";
import * as APIConstants from "../ClubManager/Apiconstants";
import * as wexerApiConstants from '../../store/APIConstants';
import { Translate } from "../../utils/Translate";
import { GetUserDetail } from "../../store/actions/auth";
import { useNavigate } from "react-router";

const config = require('../../assets/config.json')
//import { logoutUnsubscribedUsers, logout } from '../../containers/screens/login/action'


var Environment = require('./config');

export const getThirdPartyUserSubscription = () => {
  return dispatch => {
    var url = wexerApiConstants.getUserSubscriptionAPI
    dispatch({
      type: actionTypes.FETCH_USER_SUBSCRIPTION_START
    });
    var body = {
      tenantId: config.name
    }
    userApi.post(url, body).then(response => {
      if (response.ok) {
        if (response.status === 200) {
          dispatch({
            type: actionTypes.FETCH_USER_SUBSCRIPTION_SUCCESS,
            userSubscripton: response.data,
            status: response.data?.status
          });
        }
        else if (response.status === 204) {
          dispatch({
            type: actionTypes.FETCH_USER_SUBSCRIPTION_FAIL,
            userSubscripton: null,
            status: null
          });
        }
      } else {
        dispatch(logout())
        dispatch({
          type: actionTypes.FETCH_USER_SUBSCRIPTION_FAIL,
          userSubscripton: null,
          status: null
        })
      }
    })
  };
}


export const logout = () => {
  return dispatch => {
    if (config.canUnsubcribedUsersLogin == false) {

      localStorage.removeItem("token");
      localStorage.removeItem("userId");
      dispatch({
        type: actionTypes.AUTH_LOGOUT,
        status: false
      });

    }
  }
}
export const createThirdPartyUser = (data) => {
  return dispatch => { }
};
export const AuthenticateThirdPartyUser = (params) => {

  return dispatch => {
    dispatch({ type: actionTypes.THIRD_PARTY_AUTH_START });
    const queries = new URLSearchParams(window.location.search);
    let params = {}
    for (let pair of queries.entries()) {
      params[pair[0]] = pair[1]
    }
    const parameter = params

    if (!parameter?.errcode) {
      localStorage.setItem("thirdPartyAuthor", parameter.token);
      localStorage.setItem("token", parameter.token);
      localStorage.setItem("userId",parameter.userid)
      
      try {
        if (parameter.token && parameter.userid && parameter.path) {
        dispatch({
          type: actionTypes.AUTH_SUCCESS,
          token: parameter.token,
          userId: parameter.userid
        });
        } else {
          dispatch(logout())
          window.open(config.magicLink, "_self")
        }
      } catch (error) {
        console.log(error);
      }
    }
    else {
        console.log("authfailed");
        dispatch({
          type: actionTypes.AUTH_FAIL,
          error: parameter.error ?? Translate({ id: 'ApiResponseStrings.GenericError' })
        });
      }
    }

  };

export const thirdPartyResetPassword = emailId => {
  return dispatch => {
    dispatch({ type: actionTypes.RESET_PASSWORD_START });
    var body = {
      username: emailId
    };

    // thirdPartyApi()
    //   .post(APIConstants.ResetPassword + emailId)
    //   .then(response => {
    //     console.log(response)
    //     if (response.ok) {
    //       if (response.status == 200) {
    //         if (response.data.length > 0 && response.data[0].status == "sent") {
    //           dispatch({ type: actionTypes.SEND_FORGOT_PASSWORD_MAIL_SUCCESS });
    //           return response.data;
    //         }
    //         else {
    //           dispatch({ type: actionTypes.SEND_FORGOT_PASSWORD_MAIL_FAIL, errorMsg: "No account could be found associated with this email address." });
    //           console.log(response);
    //         }

    //       } else {
    //         dispatch({ type: actionTypes.SEND_FORGOT_PASSWORD_MAIL_FAIL });
    //         console.log(response);
    //       }
    //     } else {
    //       dispatch({
    //         type: actionTypes.SEND_FORGOT_PASSWORD_MAIL_FAIL
    //       });
    //     }
    //   });

  };
};

